import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import React from "react";
import { Link } from "react-router-dom";
import { Members } from "../../components/Members/Members";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { usePermissions } from "../../hooks/usePermissions";

export const Business = () => {
  const { userBusinessId } = useUserContext();
  const business = useBusinessContext();

  const [canViewMembers] = usePermissions("Member.Search");

  const pageTitle = canViewMembers ? "Members" : "Venues";

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>{pageTitle}</PageHeading.Title>
      </PageHeading>
      <PageContent>
        {canViewMembers && <Members businessId={business.BusinessId} />}
      </PageContent>
    </>
  );
};
